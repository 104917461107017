<template>
    <b-container fluid>
        <b-row>
            <b-col cols="12">
                <!-- <b-card>
                    <template #header v-if="isMobile || (this.width<700)"> -->
                <div class="card">
                    <div class="card-header" v-if="isMobile || (this.width<700)">
                        <h4 class="card-header-title"></h4>
                        <form class="mr-3" v-show="tabIndex == 0">
                            <Select2
                                v-model="perUserPage"
                                :options="perPageOptions"
                                :settings="{
                                minimumResultsForSearch: -1,
                                containerCssClass:
                                    'custom-select custom-select-sm form-control-flush',
                                dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                                }"
                                @change="paginatedKeyUsersList"
                            />
                        </form>
                        <form class="mr-3" v-show="tabIndex == 1">
                            <Select2
                                v-model="perDevicePage"
                                :options="perPageOptions"
                                :settings="{
                                minimumResultsForSearch: -1,
                                containerCssClass:
                                    'custom-select custom-select-sm form-control-flush',
                                dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                                }"
                                @change="paginatedKeyDeviceList"
                            />
                        </form>
                        <form
                            class="mr-3"
                            v-if="$can('viewDoors', getActiveLocation) && !isManagerRole"
                            v-show="tabIndex == 1"
                        >
                            <Select2
                                :options="getUnassignedDoorsList"
                                @select="addDoor"
                                :settings="{
                                    placeholder: $t('doors.add'),
                                    allowClear: true,
                                    minimumResultsForSearch: 1,
                                    containerCssClass:
                                        'custom-select custom-select-sm form-control-flush',
                                    dropdownCssClass:
                                        'dropdown-menu dropdown-menu-sm dropdown-menu-addition',
                                }"
                            />
                        </form>
                        <form
                            class="mr-3"
                            v-else
                            v-show="tabIndex == 1"
                        >
                            <Select2
                                :options="getUnassignedDoorsList"
                                @select="addDoor"
                                :settings="{
                                    placeholder: $t('doors.add'),
                                    allowClear: true,
                                    minimumResultsForSearch: 1,
                                    containerCssClass:
                                        'custom-select custom-select-sm form-control-flush',
                                    dropdownCssClass:
                                        'dropdown-menu dropdown-menu-sm dropdown-menu-addition',
                                }"
                            />
                        </form>
                        <b-button
                            v-show="tabIndex == 0 && KeyCanShare && false"
                            variant="primary"
                            size="sm"
                            @click="inviteMember"
                            v-if="
                                getActiveLocation &&
                                $can('shareKey', getActiveLocation) && isManagerRole
                            "
                            >{{ $t("keys.send_invite") }}</b-button
                        >
                        <b-button
                            v-show="tabIndex == 0 && KeyCanShare && false"
                            variant="primary"
                            size="sm"
                            @click="inviteMember"
                            v-else
                            >{{ $t("keys.send_invite") }}</b-button
                        >
                        <form class="col-auto mr-n3" v-show="tabIndex == 2">
                            <Select2
                            v-model="perKeyPage"
                            :options="perPageOptions"
                            :settings="{
                                minimumResultsForSearch: -1,
                                containerCssClass: 'custom-select custom-select-sm form-control-flush',
                                dropdownCssClass: 'dropdown-menu dropdown-menu-sm dropdown-menu-addition',
                            }"
                            @change="onPerPageChanged"
                            />
                        </form>
                    </div>
                    <!-- </template>
                    <template #header v-else> -->
                    <div class="card-header" v-else>
                        <h4 class="card-header-title"></h4>
                        <form class="mr-3" v-show="tabIndex == 0">
                            <Select2
                                v-model="perUserPage"
                                :options="perPageOptions"
                                :settings="{
                                minimumResultsForSearch: -1,
                                containerCssClass:
                                    'custom-select custom-select-sm form-control-flush',
                                dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                                }"
                                @change="paginatedKeyUsersList"
                            />
                        </form>
                        <form class="mr-3" v-show="tabIndex == 1">
                            <Select2
                                v-model="perDevicePage"
                                :options="perPageOptions"
                                :settings="{
                                minimumResultsForSearch: -1,
                                containerCssClass:
                                    'custom-select custom-select-sm form-control-flush',
                                dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                                }"
                                @change="paginatedKeyDeviceList"
                            />
                        </form>
                        <form
                            class="mr-3"
                            v-if="$can('viewDoors', getActiveLocation) && !isManagerRole"
                            v-show="tabIndex == 1"
                        >
                            <Select2
                                :options="getUnassignedDoorsList"
                                @select="addDoor"
                                :settings="{
                                    placeholder: $t('doors.add'),
                                    allowClear: true,
                                    minimumResultsForSearch: 1,
                                    containerCssClass:
                                        'custom-select custom-select-sm form-control-flush',
                                    dropdownCssClass:
                                        'dropdown-menu dropdown-menu-sm',
                                }"
                            />
                        </form>
                        <form
                            class="mr-3"
                            v-else
                            v-show="tabIndex == 1"
                        >
                            <Select2
                                :options="getUnassignedDoorsList"
                                @select="addDoor"
                                :settings="{
                                    placeholder: $t('doors.add'),
                                    allowClear: true,
                                    minimumResultsForSearch: 1,
                                    containerCssClass:
                                        'custom-select custom-select-sm form-control-flush',
                                    dropdownCssClass:
                                        'dropdown-menu dropdown-menu-sm',
                                }"
                            />
                        </form>
                        <b-button
                            v-show="tabIndex == 0 && KeyCanShare && false"
                            variant="primary"
                            size="sm"
                            @click="inviteMember"
                            v-if="
                                getActiveLocation &&
                                $can('shareKey', getActiveLocation) && isManagerRole
                            "
                            >{{ $t("keys.send_invite") }}</b-button
                        >
                        <b-button
                            v-show="tabIndex == 0 && KeyCanShare"
                            variant="primary"
                            size="sm"
                            @click="inviteMember"
                            v-else
                            >{{ $t("keys.send_invite") }}</b-button
                        >
                        <form class="col-auto mr-n3" v-show="tabIndex == 2">
                            <Select2
                            v-model="perKeyPage"
                            :options="perPageOptions"
                            :settings="{
                                minimumResultsForSearch: -1,
                                containerCssClass: 'custom-select custom-select-sm form-control-flush',
                                dropdownCssClass: 'dropdown-menu dropdown-menu-sm dropdown-menu-addition',
                            }"
                            @change="onPerPageChanged"
                            />
                        </form>
                    </div>
                    <!-- </template> -->
                   <div class="card-header" v-if="tabIndex == 0">
                        <form>
                            <div class="input-group input-group-flush input-group-merge">

                                <b-form-input
                                    :placeholder="$t('common.search')"
                                    v-model="filterUser"
                                    class="form-control form-control-prepended search"
                                    type="search"
                                ></b-form-input>

                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                    <span class="fe fe-search"></span>
                                    </div>
                                </div>
                            </div>
                    </form>
                    </div>
                    <div class="card-header" v-if="tabIndex == 1">
                        <form>
                            <div class="input-group input-group-flush input-group-merge">

                                <b-form-input
                                    :placeholder="$t('common.search')"
                                    v-model="filterDevice"
                                    class="form-control form-control-prepended search"
                                    type="search"
                                ></b-form-input>

                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                    <span class="fe fe-search"></span>
                                    </div>
                                </div>
                            </div>
                    </form>
                    </div>
                    <div class="card-body">
                    <div v-show="tabIndex == 0" transition="fade">
                    <!-- :showLoader="showUsersLoader" -->
                    <b-pagination
                            v-model="currentUserPage"
                            :total-rows="getKeyUsersById(doorKey.Id).length"
                            :per-page="perUserPage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                        <zs-list-transition :showLoader="showUsersLoader">
                            <template v-slot:content>
                               <!--  v-for="user in getKeyUsersById(doorKey.Id)" -->
                                <key-user-item
                                    v-for="user in paginatedKeyUsersList"
                                    v-bind:key="user.User.Id"
                                    :user="user"
                                    :show-intercom=IntercomVisible
                                />
                            </template>
                            <template v-slot:empty>
                                <empty-list :title="$t('keys.no_users')" />
                            </template>
                        </zs-list-transition>
                        <b-pagination
                            v-model="currentUserPage"
                            :total-rows="getKeyUsersById(doorKey.Id).length"
                            :per-page="perUserPage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                    </div>
                    <div
                        v-show="tabIndex == 1"
                        transition="fade"
                        v-if="$can('viewDoors', getActiveLocation) && !isManagerRole"
                    >
                    <b-pagination
                            v-model="currentDevicePage"
                            :total-rows="getKeyDevices.length"
                            :per-page="perDevicePage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                        <zs-list-transition :showLoader="showDoorsLoader">
                       <!--  v-for="door in getKeyDevices" -->
                            <template v-slot:content>
                                <b-list-group-item
                                    v-for="door in paginatedKeyDeviceList"
                                    v-bind:key="door.Device.Id"
                                    class="flex-column align-items-start"
                                >
                                    <b-row align-v="center">
                                        <b-col class="col-auto">
                                            <h4 class="mb-1 name">
                                                <router-link
                                                    class="ml-2"
                                                    :to="{
                                                        name: 'door',
                                                        params: {
                                                            locationId:
                                                                $route.params
                                                                    .locationId,
                                                            companyId:
                                                                $route.params
                                                                    .companyId,
                                                            doorId: door.Device
                                                                .Id,
                                                        },
                                                    }"
                                                    >{{
                                                        door.Device.Name
                                                    }}</router-link
                                                >
                                            </h4>
                                        </b-col>
                                        <b-col class="col ml-n2">
                                            <b-dropdown
                                                variant="outline"
                                                toggle-class="dropdown-ellipses dropdown-toggle"
                                                no-caret
                                                right
                                                toggle-tag="a"
                                                class="mx-1 btn-sm float-right"
                                            >
                                                <template v-slot:button-content>
                                                    <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                                                </template>
                                                <b-dropdown-item
                                                    class="text-left text-truncate"
                                                    @click="deleteDoor(door.Device.Id)"
                                                    >{{ $t("buttons.disconnect_door") }}</b-dropdown-item
                                                >
                                            </b-dropdown>
                                        </b-col>
                                    </b-row>
                                </b-list-group-item>
                            </template>
                            <template v-slot:empty>
                                <empty-list :title="$t('keys.no_doors')" />
                            </template>
                        </zs-list-transition>
                        <b-pagination
                            v-model="currentDevicePage"
                            :total-rows="getKeyDevices.length"
                            :per-page="perDevicePage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                    </div>
                    <div
                        v-show="tabIndex == 1"
                        transition="fade"
                        v-else
                    >
                    <b-pagination
                            v-model="currentDevicePage"
                            :total-rows="getKeyDevices.length"
                            :per-page="perDevicePage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                        <zs-list-transition :showLoader="showDoorsLoader">
                       <!--  v-for="door in getKeyDevices" -->
                            <template v-slot:content>
                                <b-list-group-item
                                    v-for="door in paginatedKeyDeviceList"
                                    v-bind:key="door.Device.Id"
                                    class="flex-column align-items-start"
                                >
                                    <b-row align-v="center">
                                        <b-col class="col-auto">
                                            <h4 class="mb-1 name">
                                                <router-link
                                                    class="ml-2"
                                                    :to="{
                                                        name: 'door',
                                                        params: {
                                                            locationId:
                                                                $route.params
                                                                    .locationId,
                                                            companyId:
                                                                $route.params
                                                                    .companyId,
                                                            doorId: door.Device
                                                                .Id,
                                                        },
                                                    }"
                                                    >{{
                                                        door.Device.Name
                                                    }}</router-link
                                                >
                                            </h4>
                                        </b-col>
                                        <b-col class="col ml-n2">
                                          <b-dropdown
                                                variant="outline"
                                                toggle-class="dropdown-ellipses dropdown-toggle"
                                                no-caret
                                                right
                                                toggle-tag="a"
                                                class="mx-1 btn-sm float-right"
                                            >
                                                <template v-slot:button-content>
                                                    <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                                                </template>
                                                <b-dropdown-item
                                                    class="text-left text-truncate"
                                                    @click="deleteDoor(door.Device.Id)"
                                                    >{{ $t("buttons.disconnect_door") }}</b-dropdown-item
                                                >
                                            </b-dropdown>
                                        </b-col>
                                    </b-row>
                                </b-list-group-item>
                            </template>
                            <template v-slot:empty>
                                <empty-list :title="$t('keys.no_doors')" />
                            </template>
                        </zs-list-transition>
                        <b-pagination
                            v-model="currentDevicePage"
                            :total-rows="getKeyDevices.length"
                            :per-page="perDevicePage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                    </div>
                    <div
                        v-show="tabIndex == 2"
                        transition="fade"
                        v-if="$can('viewActivity', getActiveLocation) && !isManagerRole"
                    >
                        <!-- <zs-activity-timeline
                            v-show="tabIndex == 2"
                            transition="fade"
                            v-if="activity.length && !loading"
                            :activityLog="activity"
                        /> -->
                        <!-- <zs-activity-timeline
                            v-show="tabIndex == 2"
                            transition="fade"
                            v-if="activity.length && !loading"
                            :activityLog="paginatedActivityList"
                        /> -->
                        <b-pagination
                                v-model="currentKeyPage"
                                :total-rows="totalRows"
                                :per-page="perKeyPage"
                                aria-controls="my-table"
                                class="pb-2"
                        ></b-pagination>
                        <div v-if="loading" class="d-flex justify-content-center text-center my-3">
                          <b-spinner :label="$t('common.loading')"></b-spinner>
                        </div>
                        <div v-else-if="activity.length">
                          <zs-activity-key-timeline
                              v-show="tabIndex == 2"
                              transition="fade"
                              :activityLog="paginatedKeyActivityList"
                          />
                          <b-pagination
                            v-model="currentKeyPage"
                            :total-rows="totalRows"
                            :per-page="perKeyPage"
                            aria-controls="my-table"
                            class="pb-2"
                          ></b-pagination>
                        </div>
                        <div v-else>
                          <empty-list :title="$t('keys.no_activity')" />
                        </div>
                    </div>
                    <div
                        v-show="tabIndex == 2"
                        transition="fade"
                        v-else
                    >
                      <b-pagination
                        v-model="currentKeyPage"
                        :total-rows="totalRows"
                        :per-page="perKeyPage"
                        aria-controls="my-table"
                        class="pb-2"
                      ></b-pagination>
                      <div v-if="loading" class="d-flex justify-content-center text-center my-3">
                        <b-spinner :label="$t('common.loading')"></b-spinner>
                      </div>
                      <div v-else-if="activity.length">
                        <zs-activity-key-timeline
                            v-show="tabIndex == 2"
                            transition="fade"
                            v-if="activity.length && !loading"
                            :activityLog="paginatedKeyActivityList"
                        />
                        <b-pagination
                                v-model="currentKeyPage"
                                :total-rows="totalRows"
                                :per-page="perKeyPage"
                                aria-controls="my-table"
                                class="pb-2"
                        ></b-pagination>
                      </div>
                        <div v-else>
                          <empty-list :title="$t('keys.no_activity')" />
                        </div>
                    </div>
                    </div>
                </div>
               <!--  </b-card> -->
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import ListTransition from "@/components/common/ListTransition";
import DoorUserItem from "@/components/device/DoorUserItem";
import KeyUserItem from "@/components/key/KeyUserItem";
import DeleteKeyUser from "@/components/modals/DeleteKeyUser";
import KeyTimeline from "@/components/activity/KeyTimeline";
import Select2 from "@/components/common/Select2";
import EmptyList from "@/components/common/EmptyList";

import { mapGetters } from "vuex";
import { DEVICES_GET } from "@/store/types/devices";
import { LOCATION_GET } from "@/store/types/locations";
import {
    KEY_DEVICES_GET,
    KEY_USERS_GET,
    KEY_DEVICE_REMOVE,
    KEY_DEVICE_ADD,
} from "@/store/types/keys";
import { SET_MODAL_DATA } from "@/store/types/global";
import { M_MEMBER_INVITE_FORM } from "@/components/modals/types";

import activityMixin from "@/mixins/activityMixin";
import { ACTIVITY_LOG_KEY, ACTIVITY_LOG_KEY_PG } from "@/handlers/const";
import { inviteStatuses } from "@/handlers/enums";
import { USER_GET_KEYS } from "@/store/types/user";
import { ROLE_COMPANY_MANAGER, ROLE_COMPANY_OWNER, ROLE_KEY_MANAGER, ROLE_LOCATION_MANAGER } from "@/acl/roles";
import { sleep } from "@/store/modules/global";

export default {
    mixins: [activityMixin],
    data() {
        return {
            key: null,
            showUsersLoader: true,
            showDoorsLoader: true,
            activity: [],
            newKeyList: [],
            isMobile: false,
            width: window.innerWidth,
            height: window.innerHeight,
            perUserPage: 20,
            currentUserPage: 1,
            perDevicePage: 20,
            currentDevicePage: 1,
            perKeyPage: 20,
            currentKeyPage: 1,
            perPageOptions: [
                { id: 10, text: "10 " + this.$t("menu.per_page") },
                { id: 20, text: "20 " + this.$t("menu.per_page") },
                { id: 50, text: "50 " + this.$t("menu.per_page") },
            ],
            filterUser: "",
            filterDevice: "",
            isManagerRole: false,
            userId: null,
            KeyCanShare: false,
            IntercomVisible: false,
        };
    },
    props: ["doorKey", "tabIndex"],
    computed: {
        ...mapGetters([
            "getLocationDevices",
            "getKeyDevices",
            "getInitProcessing",
            "getActiveLocation",
            "getKeyUsersById",
            "getUserKeys",
            "getUser",
            "getActiveUserRole",
        ]),
        getUnassignedDoorsList() {
            return this.getLocationDevices
                .filter((item) => {
                    if (!this.getKeyDevices.length) {
                        return true;
                    }
                    return !this.getKeyDevices.find(
                        (door) => door.Device.Id == item.Device.Id
                    );
                })
                .sort((i1, i2) => {
                    return i1.Device.Name.toUpperCase() > i2.Device.Name.toUpperCase() ? 1 : -1;
                })
                .map((door) => {
                    return { id: door.Device.Id, text: door.Device.Name };
                });
        },
        keyUsersList(){
            return this.getKeyUsersById(this.$route.params.keyId)
                .filter((item)=>item.User.Name.toLowerCase().includes(this.filterUser.toLowerCase()))
        },
        paginatedKeyUsersList() {
            return this.keyUsersList.slice(
                (this.currentUserPage - 1) * this.perUserPage,
                this.currentUserPage * this.perUserPage
            );
        },
        keyDeviceList(){
            return this.getKeyDevices
                .filter((item)=>item.Device.Name.toLowerCase().includes(this.filterDevice.toLowerCase()))
        },
        paginatedKeyDeviceList() {
            return this.keyDeviceList.slice(
                (this.currentDevicePage - 1) * this.perDevicePage,
                this.currentDevicePage * this.perDevicePage
            );
        },
        paginatedKeyActivityList() {
          if (this.currentKeyPage === this.currPage + 1) {
            return this.activity
          }
          else if (this.currentKeyPage === this.nextPage + 1) {
            return this.activityNext
          }
          else {
            if (this.currentKeyPage === 1)
              this.initActivity()
            else
              this.loadActivityPage(this.currentKeyPage - 1)

            return this.activity
          }
        },
    },
    async created() {
        await Promise.all([
            this.$store.dispatch(LOCATION_GET, this.$route.params.locationId),
            this.$store
                .dispatch(KEY_USERS_GET, this.$route.params.keyId),
            this.$store
                .dispatch(DEVICES_GET, this.$route.params.locationId),
            this.$store
               .dispatch(KEY_DEVICES_GET, this.$route.params.keyId),
        ]);
        this.showDoorsLoader = false
        this.showUsersLoader = false

        // wait for init completed
        for (let i = 0; i < 30; i++) {
            await sleep(100)
            if (this.getInitProcessing === false) {
                break
            }
        }

        this.userId = this.getUser?.Id
        window.addEventListener("resize", this.onResize);
        window.addEventListener("onload", this.onLoad);

        if (!this.getUserKeys)
            await this.$store.dispatch(USER_GET_KEYS)

        this.KeyCanShare = this.getUserKeys
            .filter((item) =>
                (item.KeyId === this.$route.params.keyId) && (item.MayShare || item.UserId === item.Key.OwnerId || this._managementRole(item.Role?.Name))
            )
            .length > 0;

        this.IntercomVisible = this.getKeyDevices
            .filter((item) =>
                ((item.Device.ProviderType === 3) || (item.Device.ProviderType === 5) || (item.Device.ProviderType === 6))
            )
            .length > 0;

        if( this.getActiveUserRole === "CompanyOwner" || this.getActiveUserRole === "CompanyManager" || this.getActiveUserRole === "LocationManager" || this.getActiveUserRole === "KeyManager"){
            this.isManagerRole = true
        }

        this.initActivity()
        /*this.loadActivity(
            {
                keyId: this.$route.params.keyId,
                UserId: (!this.isManagerRole && this.userId)? this.userId : null,
            },
            ACTIVITY_LOG_KEY
        );*/
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
        this.isMobile = false;
    },
    methods: {
      initActivity() {
        this.loadActivityPage(0)
      },
      loadActivityPage(pageNum) {
        this.activity = [];
        const params = {
          keyId: this.$route.params.keyId,
          UserId: (!this.isManagerRole && this.userId)? this.userId : null,
          IgnorePagination: false,
          limit: this.perKeyPage,
          page: (pageNum) ? pageNum : 0,
        }

        if (params.page === 0 || !this.totalRows)
          this.loadActivityPg(params, ACTIVITY_LOG_KEY_PG);

        this.loadActivity(params, ACTIVITY_LOG_KEY);
      },
      onPerPageChanged() {
        this.initActivity();
      },
        initialLoad() {
            this.$store
                .dispatch(LOCATION_GET, this.$route.params.locationId)
                .then((response) => {
                    this.loadActivity(
                            {
                                keyId: this.$route.params.keyId,
                            },
                            ACTIVITY_LOG_KEY
                        );
                    this.$store.dispatch(
                            DEVICES_GET,
                            this.$route.params.locationId
                        );
                    this.$store
                            .dispatch(KEY_DEVICES_GET, this.doorKey.Id)
                            .then(() => (this.showDoorsLoader = false));
                    this.$store
                            .dispatch(KEY_USERS_GET, this.doorKey.Id)
                            .then(() => (this.showUsersLoader = false));
                });
        },
        _managementRole(name) {
            return (
                name == ROLE_COMPANY_OWNER ||
                name == ROLE_COMPANY_MANAGER ||
                name == ROLE_LOCATION_MANAGER ||
                name == ROLE_KEY_MANAGER
            );
        },
        addDoor(door) {
            this.$store.dispatch(KEY_DEVICE_ADD, {
                keyId: this.doorKey.Id,
                deviceId: door.id,
                locationId: this.doorKey.LocationId,
            });
        },
        deleteDoor(doorId) {
            this.$store.dispatch(KEY_DEVICE_REMOVE, {
                keyId: this.doorKey.Id,
                deviceId: doorId,
                locationId: this.doorKey.LocationId,
            });
        },
        getStatusColor(statusId) {
            return inviteStatuses.find((item) => item.value == statusId)
                .variant;
        },
        inviteMember() {
            this.$store.commit(SET_MODAL_DATA, {
                data: { keyId: this.doorKey.Id },
                type: M_MEMBER_INVITE_FORM,
            });
            this.$bvModal.show(M_MEMBER_INVITE_FORM);
        },
        onResize(e) {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
        },
        onLoad(evt){
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.isMobile = true
            } else if(typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1){
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
    },
    components: {
        "key-user-item": KeyUserItem,
        Select2,
        EmptyList,
        "zs-list-transition": ListTransition,
        "zs-activity-key-timeline": KeyTimeline,
    },
};
</script>
<style scoped>
.select2-search__field {
    width: 100% !important;
    padding: 0.125rem 2.5rem !important;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='16' opacity='0.5' class='bi bi-search' style='margin-left: 2px' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat left;
}
li.select2-search--inline {
    width: 100% !important;
}

.list-group-item:last-child{
  overflow-y: hidden !important;
}
.btn:focus{
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
</style>
