<template>
    <div class="card-body" v-if="activityLog.length"> 
        <!-- List group -->
        <div class="list-group list-group-flush list-group-activity my-n3">            
            <template v-for="item in activityLog">   
                <zs-activity-invite-created
                    v-if="(item.Type == ACTIVITY_TYPE_INVITE_CREATED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />         
                <zs-activity-key-deleted
                    v-if="(item.Type == ACTIVITY_TYPE_KEY_DELETED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-key-created
                    v-if="(item.Type == ACTIVITY_TYPE_KEY_CREATED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-key-updated
                    v-if="(item.Type == ACTIVITY_TYPE_KEY_UPDATED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-user-removed-from-key
                    v-if="(item.Type == ACTIVITY_TYPE_USER_REMOVED_FROM_KEY)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />           
                <zs-activity-devices-connected
                    v-if="(item.Type == ACTIVITY_TYPE_CONNECT_DEVICES)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-devices-disconnected
                    v-if="(item.Type == ACTIVITY_TYPE_DISCONNECT_DEVICES)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                /> 
            </template>
        </div>
    </div>
    <div class="card-body" v-else>
        <empty-list :title="$t('activity.no_activity')" />
    </div>
</template>

<script>
import ActivityInviteCreated from "@/components/activity/ActivityInviteCreated";
import ActivityKeyDeleted from "@/components/activity/ActivityKeyDeleted";
import ActivityKeyCreated from "@/components/activity/ActivityKeyCreated";
import ActivityKeyUpdated from "@/components/activity/ActivityKeyUpdated";
import ActivityUserRemovedFromKey from "@/components/activity/ActivityUserRemovedFromKey";
import ActivityDevicesConnected from "@/components/activity/ActivityDevicesConnected";
import ActivityDevicesDisconnected from "@/components/activity/ActivityDevicesDisconnected";
import EmptyList from "@/components/common/EmptyList";

import {
    ACTIVITY_TYPE_ROLE_SET,
    ACTIVITY_TYPE_KEY_CREATED,
    ACTIVITY_TYPE_KEY_DELETED,
    ACTIVITY_TYPE_KEY_UPDATED,
    ACTIVITY_TYPE_DEVICE_UPDATED,
    ACTIVITY_TYPE_DEVICE_DELETED,
    ACTIVITY_TYPE_DEVICE_CREATED,
    ACTIVITY_TYPE_LOCATION_CREATED,
    ACTIVITY_TYPE_LOCATION_UPDATED,
    ACTIVITY_TYPE_LOCATION_DELETED,
    ACTIVITY_TYPE_INVITE_CREATED,
    ACTIVITY_TYPE_INVITE_ACCEPTED,
    ACTIVITY_TYPE_DEVICE_LOCKED,
    ACTIVITY_TYPE_DEVICE_UNLOCKED,
   /*  ACTIVITY_TYPE_DEVICE_LOCKED_USER,
    ACTIVITY_TYPE_DEVICE_UNLOCKED_USER, */
    ACTIVITY_TYPE_USER_REMOVED_FROM_KEY,
    ACTIVITY_TYPE_USER_REMOVED_FROM_LOCATION,
    ACTIVITY_TYPE_COMPANY_CREATED,
    ACTIVITY_TYPE_COMPANY_UPDATED,
    ACTIVITY_TYPE_COMPANY_DELETED,
    ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_CREATED,
    ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_DELETED,
    ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_UPDATED,
    ACTIVITY_TYPE_REMOVED_ROLE_COMPANY_MANAGER,
    ACTIVITY_TYPE_REMOVED_ROLE_LOCATION_MANAGER,
    ACTIVITY_TYPE_REMOVED_ROLE_KEY_MANAGER,
    ACTIVITY_TYPE_REMOVED_ROLE_INSTALLER,
    ACTIVITY_TYPE_CONNECT_DEVICES,
    ACTIVITY_TYPE_DISCONNECT_DEVICES
} from "@/handlers/const";

export default {
    data() {
        return {
            ACTIVITY_TYPE_ROLE_SET: ACTIVITY_TYPE_ROLE_SET,
            ACTIVITY_TYPE_KEY_CREATED: ACTIVITY_TYPE_KEY_CREATED,
            ACTIVITY_TYPE_KEY_DELETED: ACTIVITY_TYPE_KEY_DELETED,
            ACTIVITY_TYPE_KEY_UPDATED: ACTIVITY_TYPE_KEY_UPDATED,
            ACTIVITY_TYPE_DEVICE_UPDATED: ACTIVITY_TYPE_DEVICE_UPDATED,
            ACTIVITY_TYPE_INVITE_CREATED: ACTIVITY_TYPE_INVITE_CREATED,
            ACTIVITY_TYPE_INVITE_ACCEPTED: ACTIVITY_TYPE_INVITE_ACCEPTED,
            ACTIVITY_TYPE_LOCATION_CREATED: ACTIVITY_TYPE_LOCATION_CREATED,
            ACTIVITY_TYPE_LOCATION_UPDATED: ACTIVITY_TYPE_LOCATION_UPDATED,
            ACTIVITY_TYPE_LOCATION_DELETED: ACTIVITY_TYPE_LOCATION_DELETED,
            ACTIVITY_TYPE_DEVICE_DELETED: ACTIVITY_TYPE_DEVICE_DELETED,
            ACTIVITY_TYPE_DEVICE_CREATED: ACTIVITY_TYPE_DEVICE_CREATED,
            ACTIVITY_TYPE_DEVICE_LOCKED: ACTIVITY_TYPE_DEVICE_LOCKED,
            ACTIVITY_TYPE_DEVICE_UNLOCKED: ACTIVITY_TYPE_DEVICE_UNLOCKED,
            /* ACTIVITY_TYPE_DEVICE_LOCKED_USER: ACTIVITY_TYPE_DEVICE_LOCKED_USER,
            ACTIVITY_TYPE_DEVICE_UNLOCKED_USER: ACTIVITY_TYPE_DEVICE_UNLOCKED_USER, */
            ACTIVITY_TYPE_USER_REMOVED_FROM_KEY: ACTIVITY_TYPE_USER_REMOVED_FROM_KEY,
            ACTIVITY_TYPE_USER_REMOVED_FROM_LOCATION: ACTIVITY_TYPE_USER_REMOVED_FROM_LOCATION,
            ACTIVITY_TYPE_COMPANY_CREATED: ACTIVITY_TYPE_COMPANY_CREATED,
            ACTIVITY_TYPE_COMPANY_UPDATED: ACTIVITY_TYPE_COMPANY_UPDATED,
            ACTIVITY_TYPE_COMPANY_DELETED: ACTIVITY_TYPE_COMPANY_DELETED,
            ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_CREATED: ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_CREATED,
            ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_DELETED: ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_DELETED,
            ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_UPDATED: ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_UPDATED,
            ACTIVITY_TYPE_REMOVED_ROLE_COMPANY_MANAGER: ACTIVITY_TYPE_REMOVED_ROLE_COMPANY_MANAGER,
            ACTIVITY_TYPE_REMOVED_ROLE_LOCATION_MANAGER: ACTIVITY_TYPE_REMOVED_ROLE_LOCATION_MANAGER,
            ACTIVITY_TYPE_REMOVED_ROLE_KEY_MANAGER: ACTIVITY_TYPE_REMOVED_ROLE_KEY_MANAGER,
            ACTIVITY_TYPE_REMOVED_ROLE_INSTALLER: ACTIVITY_TYPE_REMOVED_ROLE_INSTALLER,
            ACTIVITY_TYPE_CONNECT_DEVICES: ACTIVITY_TYPE_CONNECT_DEVICES,
            ACTIVITY_TYPE_DISCONNECT_DEVICES: ACTIVITY_TYPE_DISCONNECT_DEVICES,
            adminUser: false,
        };
    },
    props: {
        activityLog: Array,
    },
    created(){
        this.adminUser = this.$session.get("adminUser");
    },
    methods: {},
    components: {
        "zs-activity-key-deleted": ActivityKeyDeleted,
        "zs-activity-key-updated": ActivityKeyUpdated,
        "zs-activity-key-created": ActivityKeyCreated,
        "zs-activity-user-removed-from-key": ActivityUserRemovedFromKey,
        "zs-activity-invite-created": ActivityInviteCreated,
        "zs-activity-devices-connected": ActivityDevicesConnected,
        "zs-activity-devices-disconnected":  ActivityDevicesDisconnected, 
        "empty-list": EmptyList,
    },
};
</script>
<style scoped>
.list-group-item:last-child{
  overflow-y: hidden !important;
}
</style>
