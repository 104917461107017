var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getActiveKey && _vm.getActiveLocation)?_c('div',{staticClass:"row justify-content-center"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"header-body"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('h6',{staticClass:"header-pretitle"},[_vm._v(" "+_vm._s(_vm.$t("keys.key"))+" ")]),_c('h1',{staticClass:"header-title text-capitalize"},[_vm._v(" "+_vm._s(_vm.keyName(_vm.getActiveKey.Name))+" ")])]),(_vm.$can('manageKey', _vm.getActiveLocation) && !_vm.roleFlag)?_c('div',{staticClass:"col-12 col-md-auto mt-3 mt-md-0"},[_c('b-dropdown',{attrs:{"text":_vm.$t('buttons.manage'),"right":"","variant":"primary","toggle-tag":"a"}},[_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":_vm.editKey}},[_vm._v(_vm._s(_vm.$t("buttons.edit")))]),_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":_vm.deleteKey}},[_vm._v(_vm._s(_vm.$t("buttons.delete")))])],1)],1):_vm._e(),(_vm.roleFlag)?_c('div',{staticClass:"col-12 col-md-auto mt-3 mt-md-0"},[_c('b-dropdown',{attrs:{"text":_vm.$t('buttons.manage'),"right":"","variant":"primary","toggle-tag":"a"}},[_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":_vm.editKey}},[_vm._v(_vm._s(_vm.$t("buttons.edit")))]),_c('b-dropdown-item',{staticClass:"text-left text-truncate",on:{"click":_vm.deleteKey}},[_vm._v(_vm._s(_vm.$t("buttons.delete")))])],1)],1):_vm._e()]),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('ul',{staticClass:"nav nav-tabs nav-overflow header-tabs"},[_c('li',{staticClass:"nav-item"},[(_vm.roleFlag)?_c('a',{staticClass:"nav-link",class:{
                                            active: _vm.tabIndex == 0,
                                        },attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.tabIndex = 0}}},[_vm._v(_vm._s(_vm.$t("users.users")))]):_vm._e()]),_c('li',{staticClass:"nav-item"},[(_vm.roleFlag)?_c('a',{staticClass:"nav-link",class:{
                                            active: _vm.tabIndex == 1,
                                        },attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.tabIndex = 1}}},[_vm._v(_vm._s(_vm.$t("doors.doors")))]):_vm._e()]),(false)?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{
                                            active: _vm.tabIndex == 2,
                                        },attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.tabIndex = 2}}},[_vm._v(_vm._s(_vm.$t("activity.activity")))])]):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{
                                            active: _vm.tabIndex == 3,
                                        },attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.tabIndex = 3}}})])])])])])])]),(_vm.getActiveKey)?_c('zs-key-tabs',{attrs:{"doorKey":_vm.getActiveKey,"tabIndex":_vm.tabIndex}}):_vm._e(),_c('zs-key-modal',{attrs:{"locationId":_vm.getActiveKey.LocationId}}),_c('zs-key-delete-modal',{attrs:{"locationId":_vm.getActiveKey.LocationId}}),_c('zs-delete-key-user-modal')],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }