<template>
    <div>
        <component v-bind:is="transformed"></component>
    </div>
</template>
<script>
export default {
    props: ["link"],
    computed: {
        transformed() {
            return {
                template: `<div class="tracking-content">${this.link}</div>`,
            };
        },
    },
};
</script>
