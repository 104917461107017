var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":_vm.type,"title":_vm.$t('keys.key'),"size":"lg","hide-footer":"","modal-class":"fixed-right","dialog-class":"modal-dialog-vertical"},on:{"show":_vm.setData,"hide":_vm.onClose}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticStyle:{"padding-left":"54px !important"},attrs:{"cols":"12"}},[_c('b-form',[_c('b-form-group',{attrs:{"label":_vm.$t('keys.name')}},[_c('b-form-input',{attrs:{"state":_vm.validateState('Name'),"id":"keyName","placeholder":_vm.$t('keys.name')},model:{value:(_vm.$v.key.Name.$model),callback:function ($$v) {_vm.$set(_vm.$v.key.Name, "$model", $$v)},expression:"$v.key.Name.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"keyName-feedback"}},[_c('b-icon',{attrs:{"icon":"exclamation-circle","variant":"danger"}}),_vm._v(" "+_vm._s(_vm.$t("errors.validation_length_range", { range: "3-200", }))+" ")],1)],1),(_vm.createMode)?_c('b-form-group',{attrs:{"label":_vm.$t('doors.doors')}},[_c('Select2',{attrs:{"options":_vm.getUnassignedDoorsList,"settings":{
                                placeholder: _vm.$t('keys.search_doors'),
                                multiple: true,
                                allowClear: true,
                                minimumResultsForSearch: 1,
                                closeOnSelect: false,
                                containerCssClass: 'custom-select ',
                                dropdownCssClass:
                                    'dropdown-menu dropdown-menu-sm',
                            }},model:{value:(_vm.keyDevices),callback:function ($$v) {_vm.keyDevices=$$v},expression:"keyDevices"}})],1):_vm._e()],1)],1)],1),_c('b-row',[_c('b-col',{staticStyle:{"padding-left":"54px !important"}},[(_vm.createMode)?_c('b-button',{staticClass:"input-field mt-10",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.postKey.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("keys.create")))]):_c('b-button',{staticClass:"input-field mt-10",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.editKey.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("keys.update")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }