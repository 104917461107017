<template>
    <b-list-group-item class="flex-column align-items-start" v-if="!isMobile">
        <b-row align-v="center" style="height: 100px;">
            <b-col lg="3" md="6" cols="6">
                <b-row>
                    <b-col cols="12">
                        <router-link
                            class="ml-2"
                            :to="{
                                name: 'profile',
                                params: {
                                    locationId: $route.params.locationId,
                                    companyId: $route.params.companyId,
                                    userId: user.User.Id,
                                },
                            }"
                        >
                            <b-avatar
                                size="sm"
                                variant="secondary"
                                :src="user.User.SmallImageUrl"
                            ></b-avatar
                            ><span class="mr-auto">
                                {{ user.User.FirstName }}
                                {{ user.User.LastName }}</span
                            ></router-link
                        >
                    </b-col>
                </b-row>
            </b-col>
            <b-col lg="3" md="6" cols="6">
                {{ keyType }}
                &nbsp;
                <b-badge v-if="_showTemporaryTime()" :variant="getTimeBadge(user.Calendar.AccessIntervals[0].EndDate)">{{ getTimeStr(user.Calendar.AccessIntervals[0].EndDate) }}</b-badge>
            </b-col>
            <b-col lg="2" md="6" cols="6">
                {{
                    (user.MayShare || _managementRole(user))
                        ? $t("keys.share_allowed")
                        : $t("keys.share_disallowed")
                }}
            </b-col>
            <b-col lg="3" md="6" cols="6">
                <!-- v-if="isPermanent && isModX" -->
                <b-form-checkbox v-if="VisibleInIntercomEnabled" @change="VisibleInIntercomChange()" v-model="isVisibleInIntercom">
                    {{$t('users.visible_in_intercom')}}
                </b-form-checkbox>
            </b-col>
            <div class="col-auto">
                <div v-b-tooltip.hover.left='$t("buttons.key_access_info")' class="mx-1 btn-sm float-right">
                  <img src="@/assets/icons/info.svg" height="24" width="24" alt="info"/>
                </div>
                <!-- Dropdown -->
                <b-dropdown
                    variant="outline"
                    toggle-class="dropdown-ellipses dropdown-toggle"
                    no-caret
                    right
                    toggle-tag="a"
                    v-if="!isOwner && false"
                >
                    <template v-slot:button-content>
                        <!-- <i class="fe fe-more-vertical"></i> -->
                        <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                    </template>
                    <!-- @click="deleteUser(user.User)" -->
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="deleteUser(user.User)"
                        >{{ $t("buttons.remove_key_user") }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="editKey()"
                        >{{ $t("buttons.edit") }}</b-dropdown-item
                    >
                </b-dropdown>
            </div>
        </b-row>
    </b-list-group-item>
    <!-- new view -->
    <b-list-group-item class="flex-column align-items-start" v-else>
                <b-row style="height:100px;">
                    <b-col lg="6" md="6" cols="6">
                        <router-link
                            class="ml-2"
                            :to="{
                                name: 'profile',
                                params: {
                                    locationId: $route.params.locationId,
                                    companyId: $route.params.companyId,
                                    userId: user.User.Id,
                                },
                            }"
                        >
                            <b-avatar
                                size="sm"
                                variant="secondary"
                                :src="user.User.SmallImageUrl"
                                style="margin-right: 10px;"
                            ></b-avatar>
                            <span class="mr-auto">
                                {{ user.User.FirstName }}
                                {{ user.User.LastName }}</span
                            ></router-link
                        >
                    </b-col>
                    <b-col lg="3" md="3" cols="3">
                        {{ keyType }}
                        &nbsp;
                        <b-badge v-if="_showTemporaryTime()" :variant="getTimeBadge(user.Calendar.AccessIntervals[0].EndDate)">{{ getTimeStr(user.Calendar.AccessIntervals[0].EndDate) }}</b-badge>
                    </b-col>
                    <b-col>
                      <div v-b-tooltip.hover.left='$t("buttons.key_access_info")' class="mx-1 btn-sm float-right">
                        <img src="@/assets/icons/info.svg" height="24" width="24" alt="info"/>
                      </div>
                        <b-dropdown
                            variant="outline"
                            toggle-class="dropdown-ellipses dropdown-toggle"
                            no-caret
                            right
                            toggle-tag="a"
                            v-if="!isOwner && false"
                            style="margin-top:-11px!important; margin-right: -63px; display: flex!important;"
                        >
                            <template v-slot:button-content>
                                <!-- <i class="fe fe-more-vertical"></i> -->
                                <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                            </template>
                            <b-dropdown-item
                                class="text-left text-truncate"
                                @click="deleteUser(user.User)"
                                >{{ $t("buttons.remove_key_user") }}</b-dropdown-item
                            >
                            <b-dropdown-item
                                class="text-left text-truncate"
                                @click="editKey()"
                                >{{ $t("buttons.edit") }}</b-dropdown-item
                            >
                        </b-dropdown>
                    </b-col>
                </b-row>
            <b-row>
            <b-col lg="6" md="6" cols="6"></b-col>
            <b-col lg="3" md="3" cols="3">
                {{
                    (user.MayShare || _managementRole(user))
                        ? $t("keys.share_allowed")
                        : $t("keys.share_disallowed")
                }}
            </b-col>
            <b-col></b-col>
            </b-row>
            <b-row>
            <b-col lg="6" md="6" cols="6"></b-col>
            <b-col lg="6" md="6" cols="6">
                <!-- v-if="isPermanent && isModX" -->
                <b-form-checkbox v-if="VisibleInIntercomEnabled" @change="VisibleInIntercomChange()" v-model="isVisibleInIntercom">
                    {{$t('users.visible_in_intercom')}}
                </b-form-checkbox>
            </b-col>
            <b-col></b-col>
            </b-row>
    </b-list-group-item>
</template>
<script>
import {
    ROLE_COMPANY_MANAGER,
    ROLE_COMPANY_OWNER, ROLE_KEY_MANAGER,
    ROLE_KEY_PERMANENT,
    ROLE_KEY_TEMPORARY,
    ROLE_LOCATION_MANAGER
} from "@/acl/roles";
import { SET_MODAL_DATA } from "@/store/types/global";
import {
    M_MEMBER_REINVITE_FORM,
    M_DELETE_KEY_USER,
    M_DELETE_DOOR_USER,
} from "@/components/modals/types";
import {
    KEY_USERS_GET,
} from "@/store/types/keys";

import { mapGetters } from "vuex";

import modalMixin from "@/mixins/modalMixin";
import MemberController from "../../api/member";

export default {
    mixins: [modalMixin],
    name: "key-user-list-item",
    data() {
        return {
            isVisibleInIntercom: this.user.VisibleInIntercom,
            isOwner: false,
            keyType: this.$t("keys.type_temporary"),
            keyTypeColor: "light",
            isPermanent: true,
            isMobile: false,
            width: window.innerWidth,
            height: window.innerHeight
        };
    },
    props: ["user", "showDeleting", "showIntercom"],
    computed: {
        ...mapGetters(["getActiveDevice","getKeyDevices",]),

        VisibleInIntercomEnabled(){
            return this._isPermanent() && this.showIntercom
        },
    },
    created() {
        this.updateContext();
        window.addEventListener("resize", this.onResize);
        window.addEventListener("onload", this.onLoad);
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },
    methods: {
        updateContext() {
            this.isOwner = this._isOwner();
            this.keyType = this._keyType();
            this.isPermanent = this._isPermanent();
        },
        getTimeStr(time) {
            let date = this.$moment(time + 'Z')
            return date.format("YYYY/MM/DD HH:mm:ss")
        },
        getTimeBadge(time) {
            try {
                let d1 = new Date(time + 'Z')
                let diff = ((new Date()).getTime() - d1.getTime()) / 1000 / 60;
                if (diff < 0)
                    return "success"
                else
                    return "secondary"
            } catch(err){
                return "danger"
            }
        },
        _isTemporary(){
            return (this.user.Role.Name == ROLE_KEY_TEMPORARY)
        },
        _showTemporaryTime(){
            return this._isTemporary() && this.user.Calendar?.AccessIntervals.length > 0 && this.user.Calendar?.AccessIntervals[0].EndDate
        },
        _isOwner() {
            return (
                !this.user.Role && this.user.Key.OwnerId == this.user.User.Id
            );
        },
        _managementRole(user) {
            return (
                user.Role?.Name == ROLE_COMPANY_OWNER ||
                user.Role?.Name == ROLE_COMPANY_MANAGER ||
                user.Role?.Name == ROLE_LOCATION_MANAGER ||
                user.Role?.Name == ROLE_KEY_MANAGER
            );
        },

        async VisibleInIntercomChange() {
            var visibleInIntercomState = 0
            if (this.isVisibleInIntercom) {
                visibleInIntercomState = 1
            } else {
                visibleInIntercomState = 0
            }

           await MemberController.updateMemberByKeyIdUserIdAndLocationIdShouldShowInIntercomList(this.user.KeyId, this.user.UserId, this.user.Key.LocationId, visibleInIntercomState);

        },

        _keyType() {
            if (this._isOwner()) {
                this.keyTypeColor = "zsprimary";
                return this.$t("keys.type_owner");
            } else if (this.user.Role.Name == ROLE_KEY_PERMANENT) {
                return this.$t("keys.type_permanent");
            } else if (
                this.user.Calendar.AccessIntervals[0].DailyAccesses.length == 0
            ) {
                return this.$t("keys.type_temporary");
            }
            return this.$t("keys.type_recurring");
        },
        _isPermanent(){
            if(this.user.Role.Name == ROLE_KEY_PERMANENT && this.user.User.Type == "Full"){
                return true;
            }else if(this.user.User.Type == "Light"){
                return false;
            }else{
                return false;
            }
        },
        deleteUser() {
            this.$store.commit(SET_MODAL_DATA, {
                data: { access: this.user },
                type: M_DELETE_KEY_USER,
            });
            this.showModal({ user: this.user }, M_DELETE_KEY_USER);
        },
        editKey() {
            this.$store.commit(SET_MODAL_DATA, {
                // data: { access: this.doorKey },
                data: { access: this.user },
                type: M_MEMBER_REINVITE_FORM,
            });
            this.$bvModal.show(M_MEMBER_REINVITE_FORM);
            if(this.$bvModal.hide(this.type)){
                this.$store.dispatch(KEY_USERS_GET, this.$route.params.keyId);
            }
        },
        onResize(e) {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
            if(this.width < 1200){
                this.isMobile = true
            }else{
                this.isMobile = false
            }
        },
        onLoad(evt){
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.isMobile = true
            } else if(typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1){
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
    },
    watch: {
        user: function() {
            this.updateContext()
        }
    },
};
</script>
<style scoped>
.btn:focus{
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
</style>
