import TransformedLink from "../TransformedLink.vue";
export default {
    computed: {
        getDate() {
            if(this.activityItem.Time == null) {return}
            return this.$moment
                .utc(this.activityItem.Time)
                .local()
                .format("MMM D, YYYY");
        },
        getTime() {
            if(this.activityItem.Time == null) {return}
            return this.$moment
                .utc(this.activityItem.Time)
                .local()
                .format("HH:mm");
        },
    },
    methods: {
        getActivityLogReason(name) {
            const reasonList = [
                {
                    txt: "SMS:",
                    reason: this.$t("activity.door_reason_sms")
                },
                {
                    txt: "Call:",
                    reason: this.$t("activity.door_reason_call")
                },
                {
                    txt: "Serial:",
                    reason: this.$t("activity.door_reason_serial")
                },
                {
                    txt: "Scheduler:",
                    reason: this.$t("activity.door_reason_schedule")
                },
            ];

            return reasonList.find(item => name.includes(item.txt))
        },
        userLink(user) {
            if (user == null) {
                return ''
            }
            /* user.FirstName = user.FirstName.replace(null, "");
            user.LastName = user.LastName.replace(null, ""); */
            if(user.FirstName == "null" || user.FirstName == null){
                user.FirstName = '';
            }
            if(user.LastName == "null" || user.LastName == null){
                user.LastName = '';
            }
            if(this.activityItem.User == null) {return}
            /* return user
                ? `<strong class='text-zssecondary'>${this.activityItem.User.FirstName || ''} ${this.activityItem.User.LastName}</strong>`
                : `<router-link to="/company/${this.activityItem.Company.Id}/location/${this.activityItem.Location.Id}/user/${this.activityItem.User.Id}">${this.activityItem.User.FirstName || ''} ${this.activityItem.User.LastName}</router-link>`
             return user
                ? `<router-link to="/company/${this.activityItem.Company.Id}/location/${this.activityItem.Location.Id}/user/${this.activityItem.User.Id}">${this.activityItem.User.Name}</router-link>`
                : this.$t("users.deleted"); */
            return user
                ? `<router-link to="/company/${this.activityItem.CompanyId}/location/${this.activityItem.LocationId}/user/${this.activityItem.UserId}">${this.activityItem.User.FirstName || ''} ${this.activityItem.User.LastName}</router-link>`
                : this.$t("users.deleted");
        },
        keyLink(key) {
            // if(key.DeletedAt === undefined || key.DeletedAt === null) {return}
            // return key.DeletedAt
            if(this.activityItem.Key == null) {return}
            return key
                ? `<strong class='text-zssecondary'>${this.activityItem.Key.Name}</strong>`
                : `<router-link to="/company/${this.activityItem.Company.Id}/location/${this.activityItem.Location.Id}/keys/${this.activityItem.Key.Id}">${this.activityItem.Key.Name}</router-link>`;
        },
        doorLink(door) {
            if(door == null) {return}
            /* console.log("in hlpr:"+this.activityItem.Device.Name)
            return door
                ? `<router-link to="/company/${this.activityItem.Company.Id}/location/${this.activityItem.Location.Id}/doors/${this.activityItem.Device.Id}">${this.activityItem.Device.Name}</router-link>`
                : this.$t("users.deleted"); */
            // return door.DeletedAt
            if(this.activityItem.Device == null) {return}
            return door
                ? `<strong class='text-zssecondary'>${this.activityItem.Device.Name}</strong>`
                : `<router-link to="/company/${this.activityItem.Company.Id}/location/${this.activityItem.Location.Id}/doors/${this.activityItem.Device.Id}">${this.activityItem.Device.Name}</router-link>`;
        },
        companyLink() {
            // return this.activityItem.Company.DeletedAt
            if(this.activityItem.Company == null) {return}
            return this.activityItem.Company
                ? `<strong class='text-zssecondary'>${this.activityItem.Company.Name}</strong>`
                : `<router-link to="/company/${this.activityItem.Company.Id}/">${this.activityItem.Company.Name}</router-link>`;
        },
        passportLink() {
            try {
                const data = JSON.parse(this.activityItem.Description);
                if(!data.PassportName) { return }
                return `<strong class='text-zssecondary'>${data.PassportName}</strong>`;
            } catch(e) {
                //console.log(e)
            }
        },
        locationLink() {
            // return this.activityItem.Location.DeletedAt
            if(this.activityItem.Location == null) {return}
            return this.activityItem.Location
                ? `<strong class='text-zssecondary'>${this.activityItem.Location.Name}</strong>`
                : `<router-link to="/company/${this.activityItem.Company.Id}/location/${this.activityItem.Location.Id}">${this.activityItem.Location.Name}</router-link>`;
        },
        saltoInstallationLink(){
            if(this.activityItem.Installation == null) {return}
            return this.activityItem.Installation
                ? `<strong class='text-zssecondary'>${this.activityItem.Installation.Installation.Name}</strong>`
                : `<router-link to="/company/${this.activityItem.Company.Id}/location/${this.activityItem.Location.Id}">${this.activityItem.Installation.Installation.Name}</router-link>`;
        }
    },
    components: {
        TransformedLink,
    },
};
