<template>
    <div
        class="row justify-content-center"
        v-if="getActiveKey && getActiveLocation"
    >
        <b-col cols="12">
            <div class="header">
                <div class="container-fluid">
                    <!-- Body -->
                    <div class="header-body">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="header-pretitle">
                                    {{ $t("keys.key") }}
                                </h6>
                                <h1 class="header-title text-capitalize">
                                    {{ keyName(getActiveKey.Name) }}
                                </h1>
                            </div>
                            <div
                                class="col-12 col-md-auto mt-3 mt-md-0"
                                v-if="$can('manageKey', getActiveLocation) && !roleFlag"
                            >
                                <b-dropdown
                                    :text="$t('buttons.manage')"
                                    right
                                    variant="primary"
                                    toggle-tag="a"
                                >
                                    <b-dropdown-item
                                        class="text-left text-truncate"
                                        @click="editKey"
                                        >{{
                                            $t("buttons.edit")
                                        }}</b-dropdown-item
                                    >
                                    <b-dropdown-item
                                        class="text-left text-truncate"
                                        @click="deleteKey"
                                        >{{
                                            $t("buttons.delete")
                                        }}</b-dropdown-item
                                    >
                                </b-dropdown>
                            </div>
                            <div
                                class="col-12 col-md-auto mt-3 mt-md-0"
                                v-if="roleFlag"
                            >
                                <b-dropdown
                                    :text="$t('buttons.manage')"
                                    right
                                    variant="primary"
                                    toggle-tag="a"
                                >
                                    <b-dropdown-item
                                        class="text-left text-truncate"
                                        @click="editKey"
                                        >{{
                                            $t("buttons.edit")
                                        }}</b-dropdown-item
                                    >
                                    <b-dropdown-item
                                        class="text-left text-truncate"
                                        @click="deleteKey"
                                        >{{
                                            $t("buttons.delete")
                                        }}</b-dropdown-item
                                    >
                                </b-dropdown>
                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col">
                                <!-- Nav -->
                                <ul
                                    class="
                                        nav nav-tabs nav-overflow
                                        header-tabs
                                    "
                                >
                                    <li class="nav-item">
                                        <a
                                            href="#"
                                            class="nav-link"
                                            @click.stop.prevent="tabIndex = 0"
                                            v-if="roleFlag"
                                            v-bind:class="{
                                                active: tabIndex == 0,
                                            }"
                                            >{{ $t("users.users") }}</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                          <!--   @click="tabIndex = 1" -->
                                        <a
                                            href="#"
                                            class="nav-link"
                                            @click.stop.prevent="tabIndex = 1"
                                            v-if="roleFlag"
                                            v-bind:class="{
                                                active: tabIndex == 1,
                                            }"
                                            >{{ $t("doors.doors") }}</a
                                        >
                                    </li>
                                    <li class="nav-item" v-if="false">
                                        <a
                                            href="#"
                                            class="nav-link"
                                            @click.stop.prevent="tabIndex = 2"
                                            v-bind:class="{
                                                active: tabIndex == 2,
                                            }"
                                        >{{ $t("activity.activity") }}</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            href="#"
                                            class="nav-link"
                                            @click.stop.prevent="tabIndex = 3"
                                            v-bind:class="{
                                                active: tabIndex == 3,
                                            }"
                                        ></a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <zs-key-tabs
                v-if="getActiveKey"
                :doorKey="getActiveKey"
                :tabIndex="tabIndex"
            />
            <zs-key-modal :locationId="getActiveKey.LocationId" />
            <zs-key-delete-modal :locationId="getActiveKey.LocationId" />
            <zs-delete-key-user-modal />
        </b-col>
    </div>
</template>

<script>
import CreateKeyModal from "@/components/modals/CreateKey";
import DeleteKeyModal from "@/components/modals/DeleteKey";
import DeleteKeyUser from "@/components/modals/DeleteKeyUser";
import Tabs from "@/components/key/Tabs";

import { mapGetters } from "vuex";

import { LOCATION_GET } from "@/store/types/locations";
import {
    KEY_GET,
    KEY_DEVICES_CLEAR,
    KEY_INVITES_CLEAR,
    KEY_CLEAR_ACTIVE,
    M_DELETE_KEY_USER,
} from "@/store/types/keys";

import { M_KEY_DELETE, M_KEY_FORM } from "@/components/modals/types";
import { keyAccessTypes } from "@/handlers/enums";

import { ROLE_KEY_PERMANENT, ROLE_KEY_TEMPORARY } from "@/acl/roles";
import modalMixin from "@/mixins/modalMixin";
import { sleep } from "@/store/modules/global";

export default {
    mixins: [modalMixin],
    data() {
        return {
            filter: "",
            tabIndex: 3,
            roleFlag: false,
        };
    },
    components: {
        "zs-key-tabs": Tabs,
        "zs-key-modal": CreateKeyModal,
        "zs-key-delete-modal": DeleteKeyModal,
        "zs-delete-key-user-modal": DeleteKeyUser,
    },
    computed: {
        ...mapGetters(["getActiveKey", "getActiveLocation", "getActiveUserRole"]),
    },

    async created() {
        await this.$store.dispatch(KEY_GET, this.$route.params.keyId);
        await this.$store
            .dispatch(LOCATION_GET, this.$route.params.locationId)
            .then((response) => {
                // this.tabIndex = 1 * !this.$can("viewUsers", response);
            });

        // wait for init completed
        for (let i = 0; i < 30; i++) {
            await sleep(100)
            if (this.getInitProcessing === false) {
                break
            }
        }

        if( this.getActiveUserRole == "CompanyOwner" || this.getActiveUserRole == "CompanyManager" || this.getActiveUserRole == "LocationManager" || this.getActiveUserRole == "KeyManager"){
            this.roleFlag = true
        }

        if (this.roleFlag)
            this.tabIndex = 0;
    },
    methods: {
        main(){
            this.getAllLocations
              .filter(
                (item) =>
                  item.Location.Id == this.$route.params.locationId
              );
        },
        editKey() {
            this.showModal({ key: this.getActiveKey }, M_KEY_FORM);
        },
        deleteKey() {
            this.showModal({ key: this.getActiveKey }, M_KEY_DELETE);
        },
        keyName(name){
            if(name.includes("Key of: ")){
                name = name.replace("Key of: ","");
            }
            return name;
        },
    },
    destroyed() {
        this.$store.commit(KEY_DEVICES_CLEAR);
        this.$store.commit(KEY_INVITES_CLEAR);
        this.$store.commit(KEY_CLEAR_ACTIVE);
    },
};
</script>
