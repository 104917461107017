<template>
    <b-modal
        :id="type"
        :title="$t('keys.key')"
        size="lg"
        hide-footer
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @show="setData"
        @hide="onClose"
    >
        <b-container fluid>
            <b-row>
                <b-col cols="12" style="padding-left: 54px !important;">
                    <b-form>
                            <!-- label-cols-sm="2"
                            label-align-sm="right"
                            label-size="sm" -->
                        <b-form-group
                            :label="$t('keys.name')"
                        >
                            <b-form-input
                                v-model="$v.key.Name.$model"
                                :state="validateState('Name')"
                                id="keyName"
                                :placeholder="$t('keys.name')"
                            />
                            <b-form-invalid-feedback id="keyName-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_length_range", {
                                        range: "3-200",
                                    })
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <!--  style="height: 250px" -->
                        <b-form-group
                            :label="$t('doors.doors')"
                            v-if="createMode"
                        >
                            <Select2
                                v-model="keyDevices"
                                :options="getUnassignedDoorsList"
                                :settings="{
                                    placeholder: $t('keys.search_doors'),
                                    multiple: true,
                                    allowClear: true,
                                    minimumResultsForSearch: 1,
                                    closeOnSelect: false,
                                    containerCssClass: 'custom-select ',
                                    dropdownCssClass:
                                        'dropdown-menu dropdown-menu-sm',
                                }"
                            />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <!-- class="mt-3" -->
                <b-col style="padding-left: 54px !important;">
                    <b-button
                        class="input-field mt-10"
                        @click.prevent.stop="postKey"
                        v-if="createMode"
                        >{{ $t("keys.create") }}</b-button
                    >
                    <b-button
                        class="input-field mt-10"
                        @click.prevent.stop="editKey"
                        v-else
                        >{{ $t("keys.update") }}</b-button
                    >
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { keyAccessTypes, weekDays } from "@/handlers/enums";
import {
    KEY_CREATE,
    KEY_PATCH,
    KEY_GET,
    KEY_DEVICES_ADD,
} from "@/store/types/keys";
import { DEVICES_GET } from "@/store/types/devices";

import { CLEAR_MODAL_DATA } from "@/store/types/global";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { M_KEY_FORM } from "@/components/modals/types";
import Select2 from "@/components/common/Select2";

export default {
    mixins: [validationMixin],
    data() {
        return {
            type: M_KEY_FORM,
            createMode: true,
            key: {},
            submitted: false,
            keyDevices: null,
            filter: "",
        };
    },
    validations: {
        key: {
            Name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(200),
            },
        },
    },
    props: ["locationId"],
    computed: {
        ...mapGetters(["getModalData", "getModalType", "getLocationDevices"]),
        keyTemplate() {
            return {
                Name: "",
                LocationId: this.locationId,
            };
        },
        getUnassignedDoorsList() {
            return [
                ...this.getLocationDevices
                .filter((item) =>
                    item.Device.Name
                    .toLowerCase()
                    .includes(this.filter.toLowerCase())
                )
                .sort((i1, i2) => {
                    return i1.Device.Name.toUpperCase() > i2.Device.Name.toUpperCase() ? 1 : -1;
                })
                .map((item) => {
                    return { id: item.Device.Id, text: item.Device.Name };
                }),
            ];
        },
    },
    created() {
        this.$store.dispatch(DEVICES_GET, this.locationId);
        this.setData();
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.key[name];
            return $dirty && this.submitted ? !$error : null;
        },
        editKey() {
            this.submitted = true;
            this.$v.key.$touch();
            if (this.$v.key.$anyError) {
                return;
            }

            this.$store
                .dispatch(KEY_PATCH, this.key)
                .then(() => {
                    this.$store.dispatch(KEY_GET, this.key.Id);
                    this.$bvModal.hide(this.type);
                })
                .catch((error) => {});
        },

        postKey() {
            this.submitted = true;
            this.$v.key.$touch();
            if (this.$v.key.$anyError) {
                return;
            }

            this.$store
                .dispatch(KEY_CREATE, this.key)
                .then((response) => {
                    this.$bvModal.hide(this.type);
                    if (this.keyDevices.length) {
                        this.$store.dispatch(KEY_DEVICES_ADD, {
                            keyId: response.data.Id,
                            devicesIds: this.keyDevices,
                            locationId: this.locationId,
                        });
                    }
                })
                .catch((error) => {});
        },
        setData() {
            if (this.getModalData !== null) {
                if(this.getModalData.key.Name.includes("Key of: ")){
                    this.getModalData.key.Name = this.getModalData.key.Name.replace("Key of: ","");
                }

                this.key = { ...this.getModalData.key };
                this.createMode = false;
            } else {
                this.key = { ...this.keyTemplate };
                this.keyDevices = null;
            }

            this.$v.key.$reset();
        },
        onClose() {
            this.$v.key.$reset();
            this.submitted = false;
            this.createMode = true;
            this.key = { ...this.keyTemplate };
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
    components: {
        Select2,
    },
};
</script>

<style>
.select2-search__field {
    width: 100% !important;
    padding: 0.125rem 2.5rem !important;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='16' opacity='0.5' class='bi bi-search' style='margin-left: 2px' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat left;
}
li.select2-search--inline {
    width: 100% !important;
}
</style>
