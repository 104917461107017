<template>
    <b-modal
        :id="type"
        :title="$t('modals.title_delete_key')"
        :ok-title="$t('buttons.delete')"
        :cancel-title="$t('buttons.cancel')"
        ok-variant="outline-danger"
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @ok="deleteKey"
        @show="setData"
        @hide="onClose"
    >
        <b-container fluid v-if="type == getModalType">
            <b-row>
                <b-col cols="12">
                    {{
                    $t("modals.text_delete_key", {
                    keyName: keyName(getModalData.key.Name),
                    })
                    }}
                    <span
                        class="bold-font-style"
                    >
                        {{
                        $t("modals.are_you_sure")
                        }}
                    </span>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { KEY_DELETE } from "@/store/types/keys";
import { M_KEY_DELETE } from "@/components/modals/types";
import { CLEAR_MODAL_DATA } from "@/store/types/global";

export default {
    data() {
        return {
            type: M_KEY_DELETE,
            key: {},
        };
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType", "getActiveLocation"]),
    },

    methods: {
        setData() {
            this.key = this.getModalData.key;
        },
        keyName(name){
            if(name.includes("Key of: ")){
                name = name.replace("Key of: ","");
            }
            return name;
        },
        deleteKey() {
            const isActive = this.$route.params.keyId == this.key.Id;
            this.$store
                .dispatch(KEY_DELETE, this.key)
                .then(() => {
                    this.$bvModal.hide(this.type);
                    if (isActive) {
                        this.$router.push({
                            name: "keys",
                            params: {
                                locationId: this.getActiveLocation.Id,
                                companyId: this.getActiveLocation.CompanyId,
                            },
                        });
                    }
                })
                .catch((error) => {});
        },
        onClose() {
            this.key = null;
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
};
</script>
